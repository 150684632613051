import React, { useEffect, useState } from 'react';
import { Box, Stack, Icon, Text } from '@bookingcom/bui-react';
import { AlertTriangleIcon } from '@bookingcom/bui-assets-react/streamline';
import PassportDisplay from './PassportDisplay/PassportDisplay';
import RbacDisplay from './RbacDisplay/RbacDisplay';
import PassportParentDisplay from './PassportParentDisplay/PassportParentDisplay';
import OnPremRequiredDisplay from './OnPremRequiredDisplay/OnPremRequiredDisplay';
import CustomMessageDisplay from './CustomMessageDisplay/CustomeMessageDisplay';
import './QueryParser.css';

const passportQuery = 'passport';
const passportParentQuery = 'passport_parent';
const rbacQuery = 'rbac';
const onPremQuery = 'on_prem_required';
const customMessageQuery = 'message';

const QueryParser = () => {
  const [ListOfPassport, setListOfPassport] = useState<string[] | undefined>([]);
  const [ListOfPassportParentItem, setListOfPassportParentItems] = useState<string[] | undefined>([]);
  const [ListOfRbacItems, setListOfRbacItems] = useState<string[] | undefined>([]);
  const [IsOnpremRequired, setIsOnPremRequired] = useState<boolean | undefined>(false);
  const [CustomMessageToUser, setCutomMessageToUser] = useState<string | undefined>(undefined);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const passportItems = queryParams.get(passportQuery);
    const rbacItems = queryParams.get(rbacQuery);
    const passportParentItems = queryParams.get(passportParentQuery);
    const isOnPremRequired = queryParams.get(onPremQuery);
    const message = queryParams.get(customMessageQuery);

    setListOfPassport(passportItems?.split(','));
    setListOfPassportParentItems(passportParentItems?.split(','));
    setListOfRbacItems(rbacItems?.split(','));
    if (isOnPremRequired?.toLowerCase() === 'true') {
      setIsOnPremRequired(true);
    } else {
      setIsOnPremRequired(false);
    }
    if (message) {
      setCutomMessageToUser(message);
    }
  }, []);

  const showMessageIfAnyValues = () => {
    if (ListOfPassport
    || ListOfPassportParentItem
    || ListOfRbacItems
    || CustomMessageToUser
    || IsOnpremRequired) {
      return (
        <Box
          backgroundColor="accent_alt"
          borderRadius="circle"
          borderColor="accent"
        >
          <Stack direction="row">
            <Icon svg={AlertTriangleIcon} size="largest" />
            <div className="message-warn">
              <Text variant="emphasized_2">
                Please follow the instructions below to request access to the resource.
                <br />
                Some policies may take awhile to sync once permission is granted.
              </Text>
            </div>
          </Stack>
        </Box>
      );
    }
  };

  return (
    <div className="query-display-body">
      <Stack>
        <br />
        {showMessageIfAnyValues()}
        {CustomMessageToUser ? <CustomMessageDisplay message={CustomMessageToUser} /> : <></> }
        {ListOfPassport ? ListOfPassport.map(x => <PassportDisplay policyNumber={x} key={x.toLowerCase()} />)
          : <></>}
        {ListOfPassportParentItem
          ? ListOfPassportParentItem.map(x => <PassportParentDisplay policyParentName={x} key={x.toLowerCase()} />)
          : <></>}
        {ListOfRbacItems ? ListOfRbacItems.map(x => <RbacDisplay policyName={x} key={x.toLowerCase()} />)
          : <></>}
        {IsOnpremRequired ? <OnPremRequiredDisplay /> : <></>}
      </Stack>
    </div>
  );
};

export default QueryParser;
