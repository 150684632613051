import { Box, Button, Title } from '@bookingcom/bui-react';
import React, { useContext, useEffect, useState } from 'react';
import './PassportDisplay.css';
import PassportLogo from './PassportLogo';
import PassportExpandedInfo from './PassportExpandedInfo/PassportExpandedInfo';
import { PassportApi, PassportPolicyInfo } from '../../../api';
import { AppContext } from '../../../Config/AppContext';

interface Props {
  policyNumber: string;
}

const PassportDisplay = (props : Props) => {
  const [policyInformation, setPolicyInformation] = useState<PassportPolicyInfo | undefined>(undefined);
  const [subtitle, setSubtitle] = useState<string>(`Request this policy from Passport : ${props.policyNumber}`);
  const context = useContext(AppContext);

  const getLink = () => {
    return `https://passport.booking.com/policy/${props.policyNumber}`;
  };

  useEffect(() => {
    getPolicyDescription();
  }, []);

  const notauthAPI = () => {
    return context.apiClients.getClient(PassportApi);
  };

  const getPolicyDescription = () => {
    notauthAPI().getPassportDescription(props.policyNumber)
      .then(response => {
        if (response) {
          setPolicyInformation(response);
          if (response.target && response.target?.length !== 0) {
            // eslint-disable-next-line max-len
            setSubtitle('You need to request this policy from Passport in order to access.');
          }
        }
      })
      .catch(error => console.log(error));
  };

  return (
    <Box
      backgroundColor="brand_primary"
      borderRadius={300}
      borderColor="accent"
      className="card-base"
    >
      <div className="card-contents">
        <Title
          color="inherit"
          variant="headline_2"
          title={<PassportLogo />}
          subtitle={subtitle}
        />
        {policyInformation?.id ? <PassportExpandedInfo policyInfo={policyInformation} />
          : (
            <>
              For more information about {props.policyNumber},
              use the button to navigate to Passport, or reach out to the service owner for assistance.
            </>
          ) }
      </div>
      <div className="passport-button">
        <a href={getLink()} target="_blank" rel="noreferrer">
          <Button
            text="Request"
          />
        </a>
      </div>

    </Box>

  );
};

export default PassportDisplay;
