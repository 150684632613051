import { Box, Button, Title, Icon } from '@bookingcom/bui-react';
import { CommonFileQuestionIcon } from '@bookingcom/bui-assets-react/streamline';
import React from 'react';
import './RbacDisplay.css';

interface Props {
  policyName: String;
}

const RbacDisplay = (props : Props) => {
  const getLink = () => {
    return `https://rbac.support.booking.com/AccessRequest/CreateRequest?group=${props.policyName}`;
  };

  const rbacTitle = () => {
    return (
      <>
        <Icon svg={CommonFileQuestionIcon} size="larger" /> RBAC
      </>
    );
  };

  return (
    <Box
      backgroundColor="elevation_one"
      borderRadius={300}
      borderColor="accent"
      className="rbac-base"
    >
      <div className="rbac-card-contents">
        <Title
          variant="headline_2"
          title={rbacTitle()}
          subtitle="You will need to request this policy from RBAC:"
        />
        <span>{props.policyName}</span>
      </div>
      <a href={getLink()} target="_blank" rel="noreferrer">
        <Button
          className="rbac-button "
          text="Request"
        />
      </a>
    </Box>

  );
};

export default RbacDisplay;
