import React, { useContext } from 'react';
import { ApiClientFactory } from './ApiClientFactory';

export interface AppContextProps {
  baseUrl: string;
  apiClients: ApiClientFactory;
  isUserAuthenticated: boolean;
  setIsUserAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

function getBaseUrl() {
  const origin = window.location.origin;
  if (origin.match(/https?:\/\/localhost(\.booking\.com):3000?/i)) {
    return 'https://localhost.booking.com:8443';
  }
  return origin;
}

const url = getBaseUrl();

export const AppContext = React.createContext<AppContextProps>({
  baseUrl: url,
  apiClients: new ApiClientFactory(url),
  isUserAuthenticated: false,
  setIsUserAuthenticated: () => {},
});

export const useAppContext = () => useContext(AppContext);
