import { Box, Title } from '@bookingcom/bui-react';
import React from 'react';
import './CustomMessageDisplay.css';

interface Props {
  message: String | undefined
}

const CustomMessageDisplay = (props : Props) => {
  return (
    <Box
      backgroundColor="callout_alt"
      borderRadius={300}
      borderColor="accent"
      className="card-base"
    >
      <Title variant="headline_2" title="Note from Service provider:" subtitle={props.message} />
    </Box>

  );
};

export default CustomMessageDisplay;
