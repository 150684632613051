/* tslint:disable */
/* eslint-disable */
/**
 * com.booking.notauthorized.notauthorizedui
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PassportPolicyInfo,
} from '../models';
import {
    PassportPolicyInfoFromJSON,
    PassportPolicyInfoToJSON,
} from '../models';

export interface PassportApiGetPassportDescriptionRequest {
    policyId: string;
}

/**
 * 
 */
export class PassportApi extends runtime.BaseAPI {

    /**
     * get description
     */
    async getPassportDescriptionRaw(requestParameters: PassportApiGetPassportDescriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PassportPolicyInfo>> {
        if (requestParameters.policyId === null || requestParameters.policyId === undefined) {
            throw new runtime.RequiredError('policyId','Required parameter requestParameters.policyId was null or undefined when calling getPassportDescription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Booking-Authentication"] = this.configuration.apiKey("X-Booking-Authentication"); // headerAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/passport/{policyId}`.replace(`{${"policyId"}}`, encodeURIComponent(String(requestParameters.policyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PassportPolicyInfoFromJSON(jsonValue));
    }

    /**
     * get description
     */
    async getPassportDescription(policyId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PassportPolicyInfo> {
        const response = await this.getPassportDescriptionRaw({ policyId: policyId }, initOverrides);
        return await response.value();
    }

}
