import * as portableFetch from 'portable-fetch';
import { FetchAPI } from '../api';

export function authAwareFetch(fetch: FetchAPI = portableFetch): FetchAPI {
  return (req, init?) => {
    const url: string = typeof req === 'string' ? req : (req instanceof Request) ? (<Request>req).url : req.toString();
    return fetch(req, {
      ...init,
      credentials: url.match(/^https:\/\/localhost/) ? 'include' : 'same-origin',
    }).then(response => {
      if (response.status === 401) {
        const apiBase = new URL(response.url).origin;
        const location = window.location;
        const { origin, pathname, hash, search } = location;
        let redirectUrl = `${pathname}${search}${hash}`;
        if (origin !== apiBase) {
          redirectUrl = `${origin}${redirectUrl}`;
        }
        window.location.href = `${apiBase}/login?redirectUri=${encodeURIComponent(redirectUrl)}`;

        throw new Error('Got unauthorized, redirecting to login');
      }
      return response;
    });
  };
}
