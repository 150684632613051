import { Box, Button, Title } from '@bookingcom/bui-react';
import React from 'react';
import './PassportParentDisplay.css';
import PassportLogo from '../PassportDisplay/PassportLogo';

interface Props {
  policyParentName: String;
}

const PassportParentDisplay = (props : Props) => {
  const getLink = () => {
    return `https://passport.booking.com/policies?target-query=${props.policyParentName}`;
  };

  return (
    <Box
      backgroundColor="brand_primary_dynamic"
      borderRadius={300}
      borderColor="accent"
      className="card-base"
    >
      <div className="passport-parents-card-contents">
        <Title
          color="inherit"
          variant="headline_2"
          title={<PassportLogo />}
          subtitle="You will need to request 1 of the permission from this Policy parent."
        />
        Parent Name: {props.policyParentName}
      </div>
      <div className="passportParent-button">
        <a href={getLink()} target="_blank" rel="noreferrer">
          <Button
            text="Find Policy"
          />
        </a>
      </div>

    </Box>

  );
};

export default PassportParentDisplay;
