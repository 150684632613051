import { BaseAPI, Configuration, FetchAPI } from '../api';
import { authAwareFetch } from './AuthHandler';

export class ApiClientFactory {
  private readonly configuration: Configuration;

  constructor(baseUrl: string, fetchAPI: FetchAPI = authAwareFetch()) {
    this.configuration = new Configuration({
      basePath: baseUrl,
      fetchApi: fetchAPI,
    });
  }

  getClient<T extends BaseAPI>(
    Client: new (configuration: Configuration) => T,
  ): T {
    return new Client(this.configuration);
  }
}
