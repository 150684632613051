import { Stack, Title, Text } from '@bookingcom/bui-react';
import React from 'react';
import './PassportExpandedInfo.css';
import { PassportPolicyInfo } from '../../../../api';

interface Props {
  policyInfo: PassportPolicyInfo,
}

const PassportExpandedInfo = (props: Props) => {
  return (
    <Stack>
      <Title color="inherit" title="Description" subtitle={props.policyInfo.description} />
      <Title color="inherit" title="Target" subtitle={props.policyInfo.target} />
      <Title color="inherit" title="Action" subtitle={props.policyInfo.action} />
      <Title color="inherit" title="Policy ID" subtitle={props.policyInfo.id} />
      <Text
        color="white"
        variant="emphasized_2"
        align="center"
      >
        Note: Some policies may take awhile to sync once the request is approved.
      </Text>
    </Stack>
  );
};

export default PassportExpandedInfo;
