import { Box, Title, Icon, Button } from '@bookingcom/bui-react';
import { WifiIcon } from '@bookingcom/bui-assets-react/streamline';
import React from 'react';
import './OnPremRequiredDisplay.css';

const OnPremRequiredDisplay = () => {
  // eslint-disable-next-line max-len
  const vpnLink = 'https://booking.service-now.com/esc?id=kb_article&table=kb_knowledge&sysparm_article=KB0015358&sys_kb_id=6f6f15c097e565140901fc70f053af9f&spa=1';

  const subTitleInfo = () => {
    return (
      <span>The app you are attempting to access requires you to be on an internal network. <br />
        If you are not in the office, you will need to connect to the VPN. <br />
        See the Service Portal for instructions and troubleshooting
        <div className="passport-button">
          <a href={vpnLink} target="_blank" rel="noreferrer">
            <Button
              text="VPN instructions"
            />
          </a>
        </div>
      </span>

    );
  };

  const titleInfo = () => {
    return (
      <>
        <Icon svg={WifiIcon} size="larger" /> Corporate Network Required for access
      </>
    );
  };

  return (
    <Box
      backgroundColor="action_alt"
      borderRadius={300}
      borderColor="accent"
      className="card-base"
    >
      <Title variant="headline_2" title={titleInfo()} subtitle={subTitleInfo()} />
    </Box>

  );
};

export default OnPremRequiredDisplay;
