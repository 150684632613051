const ISSUER = process.env.ISSUER || 'https://okta.booking.com/oauth2/default';
const OKTA_TESTING_DISABLEHTTPSCHECK = false;
const BASENAME = process.env.PUBLIC_URL || '';
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;
const DQS_CLIENT_ID = '0oa8q06wwrVsKIfcB417';
const PROD_CLIENT_ID = '0oa8s1eakm0nEZwtj417';

const getClientId : string = (window.location.origin.toLowerCase().includes('prod')
  || window.location.origin.toLowerCase() === 'https://notauthorized.booking.com/') ? PROD_CLIENT_ID : DQS_CLIENT_ID;

export default {
  oidc: {
    clientId: getClientId,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
};
