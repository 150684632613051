import React, { ReactElement } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Button } from '@bookingcom/bui-react';
import Loading from './Loading';
import './SecureRoute.css';

interface Props {
  child: ReactElement
}

export const SecureRoute = (props : Props) => {
  const { oktaAuth, authState } = useOktaAuth();

  const handleSignIn = () => {
    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  };

  if (!authState) {
    return (
      <Loading />
    );
  }
  if (authState?.isAuthenticated) {
    return (props.child);
  }
  return (
    <Button
      className="login-button"
      onClick={handleSignIn}
    >
      Login for more information
    </Button>
  );

  return (<></>);
};
