/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { OktaAuth } from '@okta/okta-auth-js';
import AppContainer from './Components/AppContainer';
import OktaConfig from './Config/OktaConfig';

const container = document.getElementById('root');
const root = createRoot(container);
const oktaAuth = new OktaAuth(OktaConfig.oidc);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppContainer oktaAuth={oktaAuth} />
    </BrowserRouter>
  </React.StrictMode>,
);
