/* tslint:disable */
/* eslint-disable */
/**
 * com.booking.notauthorized.notauthorizedui
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PassportPolicyInfo
 */
export interface PassportPolicyInfo {
    /**
     * 
     * @type {string}
     * @memberof PassportPolicyInfo
     */
    action?: string;
    /**
     * 
     * @type {string}
     * @memberof PassportPolicyInfo
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PassportPolicyInfo
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PassportPolicyInfo
     */
    object?: string;
    /**
     * 
     * @type {string}
     * @memberof PassportPolicyInfo
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof PassportPolicyInfo
     */
    target?: string;
}

/**
 * Check if a given object implements the PassportPolicyInfo interface.
 */
export function instanceOfPassportPolicyInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PassportPolicyInfoFromJSON(json: any): PassportPolicyInfo {
    return PassportPolicyInfoFromJSONTyped(json, false);
}

export function PassportPolicyInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PassportPolicyInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': !exists(json, 'action') ? undefined : json['action'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'object': !exists(json, 'object') ? undefined : json['object'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'target': !exists(json, 'target') ? undefined : json['target'],
    };
}

export function PassportPolicyInfoToJSON(value?: PassportPolicyInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': value.action,
        'description': value.description,
        'id': value.id,
        'object': value.object,
        'subject': value.subject,
        'target': value.target,
    };
}

