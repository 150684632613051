import { Security } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import React from 'react';
import { BUIProvider, Stack, Alert } from '@bookingcom/bui-react';
import LegacyTheme from '@bookingcom/bui-react/themes/legacy';
import Header from './Header';
import './AppContainer.css';
import OktaRoutes from './OktaAuthentication/OktaRoutes';
import QueryParser from './QueryParser/QueryParser';

type Props = {
  oktaAuth: OktaAuth
};

const AppContainer = (props : Props) => {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  return (
    <BUIProvider theme={LegacyTheme}>
      <Header />
      <Stack direction="column">
        <div className="top-notification">
          <div className="not-authorized-notification">
            <Alert
              title="Not Authorized"
              text="You have attempted to access an application you do not have not have policies/permission for."
              variant="error"
            />
            <Security oktaAuth={props.oktaAuth} restoreOriginalUri={restoreOriginalUri}>
              <div className="App">
                <main>
                  <OktaRoutes child={<QueryParser />} />
                </main>
              </div>
            </Security>
          </div>
        </div>

      </Stack>
    </BUIProvider>
  );
};

export default AppContainer;
