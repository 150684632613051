import React from 'react';
import './Header.css';
import { Image } from '@bookingcom/bui-react';

const Header = () => {
  return (
    <div className="bui-header siteHeader bui-header--full-width">
      <Image
        asset={{
          setName: 'images-brand',
          assetName: 'BookingComLogoDarkBackgroundsMono',
        }}
        alt="Wishlist empty state"
      />
    </div>
  );
};

export default Header;
