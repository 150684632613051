import React, { ReactElement } from 'react';
import { Routes, Route } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import { SecureRoute } from './SecureRoute';
import Loading from './Loading';

interface Props {
  child: ReactElement
}

const AppRoutes = (props : Props) => {
  return (
    <Routes>
      <Route path="login/callback" element={<LoginCallback loadingElement={<Loading />} />} />
      <Route path="/" element={<SecureRoute child={props.child} />} />
    </Routes>
  );
};

export default AppRoutes;
